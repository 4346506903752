const SPACE_DATA = {
		"u6992842":  [{space_js: "//slkiiw.renbikj.com/source/sn/static/m/vvcp/source/j/openjs/c.js", space_src: "baidujs", space_type: "inters", space_id: "u6992842"}],
		"u6992843":  [{space_js: "//slkiiw.renbikj.com/production/t/production/onw/resource/wd/qk/common/g.js", space_src: "baidujs", space_type: "native", space_id: "u6992843"}],
		"u6992844":  [{space_js: "//slkiiw.renbikj.com/production/u/openjs/p_o_xxer/ll.js", space_src: "baidujs", space_type: "native", space_id: "u6992844"}],
		"u6992845":  [{space_js: "//slkiiw.renbikj.com/site/v/q_p/common/yy_fsmn.js", space_src: "baidujs", space_type: "native", space_id: "u6992845"}],
		"u6992846":  [{space_js: "//slkiiw.renbikj.com/common/w/static/r/common/q/production/zz_gtnq.js", space_src: "baidujs", space_type: "native", space_id: "u6992846"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6992842"],
	Home_native:  SPACE_DATA["u6992843"],
	Home_banner:  SPACE_DATA["u6992844"],
	Detail_inter:  SPACE_DATA["u6992842"],
	Detail_native:  SPACE_DATA["u6992843"],
	Detail_banner:  SPACE_DATA["u6992844"],
	List_native:  SPACE_DATA["u6992843"],
	List_banner:  SPACE_DATA["u6992844"],
	Result_inter:  SPACE_DATA["u6992842"],
	Result_native:  SPACE_DATA["u6992843"],
	Result_banner:  SPACE_DATA["u6992844"],
	Result_native_center1:  SPACE_DATA["u6992845"],
	Result_native_center2:  SPACE_DATA["u6992846"],
}
export const ALL_SPACE_MAP = {
    H5_RBBZ_VO_7 : {
		Home_inter:  SPACE_DATA["u6992842"],
		Home_native:  SPACE_DATA["u6992843"],
		Home_banner:  SPACE_DATA["u6992844"],
		Detail_inter:  SPACE_DATA["u6992842"],
		Detail_native:  SPACE_DATA["u6992843"],
		Detail_banner:  SPACE_DATA["u6992844"],
		List_native:  SPACE_DATA["u6992843"],
		List_banner:  SPACE_DATA["u6992844"],
		Result_inter:  SPACE_DATA["u6992842"],
		Result_native:  SPACE_DATA["u6992843"],
		Result_banner:  SPACE_DATA["u6992844"],
		Result_native_center1:  SPACE_DATA["u6992845"],
		Result_native_center2:  SPACE_DATA["u6992846"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6992842"],
		Home_native:  SPACE_DATA["u6992843"],
		Home_banner:  SPACE_DATA["u6992844"],
		Detail_inter:  SPACE_DATA["u6992842"],
		Detail_native:  SPACE_DATA["u6992843"],
		Detail_banner:  SPACE_DATA["u6992844"],
		List_native:  SPACE_DATA["u6992843"],
		List_banner:  SPACE_DATA["u6992844"],
		Result_inter:  SPACE_DATA["u6992842"],
		Result_native:  SPACE_DATA["u6992843"],
		Result_banner:  SPACE_DATA["u6992844"],
		Result_native_center1:  SPACE_DATA["u6992845"],
		Result_native_center2:  SPACE_DATA["u6992846"],
    },
};
